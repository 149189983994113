#page-header-admin {
   width: 100vw;
   height: 11rem;
   display: flex;
   flex-direction: row;
   background: var(--color-background-top);
   overflow: hidden;
}

#page-header-admin .top-bar-container {
   width: 100vw;
   padding: 0 4rem;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

#page-header-admin .top-bar-container .logo {
   width: 9.8rem;
   height: 9.8rem;
   margin-left: -5rem;
}

#page-header-admin .top-bar-container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}

#page-header-admin .top-bar-container .top-bar-right {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}

#page-header-admin .top-bar-container .top-bar-right .link-container {
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

#page-header-admin .top-bar-container .top-bar-right .link-selected {
   margin-bottom: 1rem;
}

#page-header-admin .top-bar-container .top-bar-right .link-selected .documentation {
   margin-bottom: 1rem;
   font: 700 2rem DM Sans;
   line-height: 2.6rem;
   text-transform: capitalize;
   text-align: center;
   color: #FFF;
   text-decoration: none;
   outline: none;
}


#page-header-admin .top-bar-container .top-bar-right .button-back {
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0 0 1.3rem 4rem;
   outline: none;
}

@media(min-width: 1100px) {
   #page-header-admin {
      height: 11rem;
      justify-content: center;
   }
   #page-header-admin .top-bar-container {
      width: 120rem;
      padding: 0;
   }
   #page-header-admin .top-bar-container .logo {
      margin-left: -3rem;
   }
}
