#page-dashboard {
   width: 100vw;
   height: 100vh;
   overflow: auto;
   overflow-x: hidden;
   background-color: #262626;
}

#page-dashboard .container {
   /* height: calc(100% - 11rem); */
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

#page-dashboard .container .header {
   background: #F6F8F9;
   width: 68.4rem;
   height: 5.6rem;
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-radius: 0.8rem 0.8rem 0 0;
   padding: 1.5rem 2.8rem;
   margin-top: 2.8rem;
   border-bottom: 1px solid #D4D4D2;
}

#page-dashboard .container .header h1 {
   font: 700 2rem DM Sans;
   line-height: 2.6rem;
   color: #000;
}

#page-dashboard .container .header .pdf-button {
   border: 0;
   outline: none;
   cursor: pointer;
}

#page-dashboard .container main {
   flex: 1;
   width: 68.4rem;
   /* min-height: 40rem; */
   border-radius: 0 0 0.8rem 0.8rem;
   display: flex;
   flex-direction: column;
   align-items: center;
   background: #F6F8F9;
   margin-bottom: 3rem;
   box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.4);
   padding: 3rem;
}

#page-dashboard .container main .filters-container {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}

#page-dashboard .container main .filters-container button {
   outline: none;
   margin: 0;
}

#page-dashboard .container main .filters-container .filter-period-selected {
   position: relative;
   width: 12.1rem;
   height: 3.3rem;
   background: #FFF;
   border: 1px solid #676768;
   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
   border-radius: 1rem;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   font: 500 1.4rem DM Sans;
   line-height: 2.1rem;
   color: #333333;
   cursor: pointer;
   padding-left: 1rem;
}

#page-dashboard .container main .filters-container .filter-period {
   position: relative;
   width: 12.1rem;
   height: 3.3rem;
   background: #FFF;
   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
   border-radius: 1rem;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   font: 500 1.4rem DM Sans;
   line-height: 2.1rem;
   color: #BDBDBD;
   cursor: pointer;
   transition: background-color 0.2s;
   padding-left: 1rem;
}

#page-dashboard .container main .filters-container .filter-period:hover {
   background-color: #f1f1f1;
}

#page-dashboard .container main .filters-container svg {
   position: absolute;
   left: 0.7rem;
}

#page-dashboard .container main .filters-container .select-tasks {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   width: 35.4rem;
   height: 3.3rem;
}

#page-dashboard .container main .filters-container .select-tasks .react-select-container {
   width: 100%;
   height: 3.3rem;
   background: #FFFFFF;
   border: 1px solid #6B2722;
   border-radius: 0.6rem;
   box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
   font: 400 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #676768;
   outline: none;
   padding: 0 1rem;
   cursor: pointer;
}

#page-dashboard .container main .filters-container .select-tasks .react-select__control {
   width: 100%;
   height: 3.3rem;
   border: 0;
   background-color: transparent;
}

#page-dashboard .container main .filters-container .select-tasks .react-select__value-container {
   width: 100%;
   height: 3.3rem;
   border: 0;
   background-color: transparent;
}

#page-dashboard .container main .filters-container .select-tasks .react-select__placeholder {
   color: #333333;
}

#page-dashboard .container main .filters-container .select-tasks .react-select__menu {
   width: 88%;
}

#page-dashboard .container main .filters-container .select-tasks .react-select__option:active {
   background-color: #6B2722;
   color: #FFF;
}

#page-dashboard .container main .filters-container .select-tasks .react-select__indicators {
   display: none;
}

#page-dashboard .container main .chart-container {
   margin-top: 2.5rem;
   padding: 2.5rem;
   display: flex;
   justify-content: center;
   align-items: center;
}

#page-dashboard .container main .status-container {
   width: 100%;
   height: 7.2rem;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   background: #FFFFFF;
   box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
   border-radius: 1rem;
   margin-top: 2.5rem;
   font: 700 3.6rem DM Sans;
   line-height: 4.7rem;
   color: #626262;
}

#page-dashboard .container main .status-container .status {
   font: 700 3.6rem DM Sans;
   line-height: 4.7rem;
   color: #6B2722;
}

#page-dashboard .container main .task-completed-container {
   width: 100%;
   height: 6.1rem;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   background: #FFFFFF;
   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
   border-radius: 1rem;
   margin-top: 3.4rem;
   padding: 0 2.8rem;
}

#page-dashboard .container main .task-completed-container .task-completed-title {
   font: 700 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #000000;
   margin-right: 1.5rem;
}

#page-dashboard .container main .task-completed-container .task-completed-quantity {
   font: 700 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #262626;
}

#page-dashboard .container main .progress-container {
   width: 100%;
   height: 15.1rem;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   background: #FCFCFF;
   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
   border-radius: 1rem;
   margin-top: 2.5rem;
   font: 700 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #626262;
   padding: 1.5rem 2.4rem;
}

#page-dashboard .container main .progress-container .progress-items {
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
}

#page-dashboard .container main .progress-container .progress-items .progress {
   width: 50%;
   height: 2.7rem;
   background: #E5E5E7;
   margin-right: 2rem;
}

#page-dashboard .container main .progress-container .progress-items .progress .progress-bar {
   background: #828282;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   font: 700 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #FFFF;
   overflow: visible;
   padding: 0 1.5rem;
}

#page-dashboard .container main .progress-container .progress-items span {
   font: 700 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #262626;
}

#page-dashboard .container main .subtitles-container {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   background: #FCFCFF;
   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
   border-radius: 1rem;
   margin: 2.5rem 0;
   font: 700 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #626262;
   padding: 2rem 0;
}

#page-dashboard .container main .subtitles-container .subtitles-title {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   padding: 1.8rem;
   font: 700 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #676768;
}

#page-dashboard .container main .subtitles-container .subtitles-title span {
   margin-left: 1rem;
}

#page-dashboard .container main .subtitles-container .subtitles-options {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   padding: 2rem 4rem;
}

#page-dashboard .container main .subtitles-container .subtitles-options .option-container {
   width: 14rem;
   font: 700 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #6B2722;
   margin-right: 1rem;
}

#page-dashboard .container main .subtitles-container .subtitles-options .option-explanation-container {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   font: 400 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #676768;
}

#page-dashboard .react-datepicker, #page-dashboard .react-datepicker__month-container {
   width: 22rem !important;
}

#page-dashboard .react-datepicker__week {
   width: 22rem !important;
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
}

#page-dashboard .react-datepicker__month {
   width: 21rem !important;
   display: flex !important;
   flex-direction: column !important;
   justify-content: center !important;
   align-items: center !important;
}

#page-dashboard .react-datepicker__month-wrapper {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   padding: 0 3rem 0 2rem;
}

#page-dashboard .react-datepicker__header {
   width: 21.8rem !important;
}

#page-dashboard .react-datepicker, #page-dashboard .react-datepicker__current-month, #page-dashboard .react-datepicker__header, #page-dashboard .react-datepicker__today-button {
   font-size: 1.3rem !important;
}

#page-dashboard .react-datepicker__day-name, #page-dashboard .react-datepicker__day {
   margin: 0.5rem !important;
}

#page-dashboard .react-datepicker__month .react-datepicker__month-text, #page-dashboard .react-datepicker__month .react-datepicker__quarter-text {
   display: inline-block;
   width: 10rem !important;
   margin: 2px;
}
