#page-admin {
   width: 100vw;
   height: 100vh;
   background: var(--color-background);
}

#page-admin .container {
   height: calc(100% - 12rem);
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

#page-admin .container main {
   min-height: calc(100% - 12rem);
   background: var(--color-background-columns);
   width: 120rem;
   margin: 1.5rem 0;
   box-shadow: 0 1.5rem 1rem -1.5rem rgba(0, 0, 0, 0.4);
   border-radius: 0.5rem;
   overflow: hidden;
}

#page-admin .container main .header {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-radius: 0.8rem 0.8rem 0 0;
   padding: 2rem 0 1.5rem 3rem;
   border-bottom: 1px solid #D4D4D2;
}

#page-admin .container main .header h1 {
   font: 700 2rem DM Sans;
   line-height: 2.6rem;
   color: #000;
}

#page-admin .container main .header .filter {
   margin-right: 4rem;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   position: relative;
}

#page-admin .container main .header .filter input {
   height: 4rem;
   width: 24rem;
   font: 500 1.3rem DM Sans;
   line-height: 2.2rem;
   color: #4F4F4F;
   padding: 0 1.6rem;
   background: var(--color-input-background);
   border: 1px solid var(--color-input-border);
   border-radius: 1.6rem;
   outline: none;
}

#page-admin .container main .header .filter input:focus {
   border: 2px solid var(--color-button-submit);
}

#page-admin .container main .header .filter .icon-container {
   height: 4.5rem;
   width: 4.5rem;
   margin-left: 1rem;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   cursor: pointer;
   padding: 1rem;
   border-radius: 50%;
}

#page-admin .container main .header .filter .icon-container:hover {
   background-color: #D4D4D2;
}

#page-admin .container main .header .filter .tooltip {
   visibility: hidden;
   width: 150px;
   background-color: #262626;
   font: 500 1rem DM Sans;
   color: #FFF;
   text-align: center;
   border-radius: 6px;
   padding: 8px;
   position: absolute;
   z-index: 1;
   bottom: -80%;
   left: 50%;
   opacity: 0;
   transition: opacity 0.3s;
}

#page-admin .container main .header .filter .icon-container:hover .tooltip {
   visibility: visible;
   opacity: 1;
}

#page-admin .container main .no-users {
   width: 100%;
   height: calc(100% - 7rem);
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font: 500 1.6rem DM Sans;
   line-height: 2.2rem;
   color: #4F4F4F;
}

#page-admin .container main .users-list {
   display: flex;
   align-items: flex-start;
   justify-content: flex-start;
   flex-direction: column;
   margin: 3rem;
   height: 100%;
}

#page-admin .container main .users-list .users-list-header {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   font: 500 1.4rem DM Sans;
   line-height: 2.2rem;
   color: #262626;
   text-align: center;
   padding: 0 3rem;
   margin-bottom: 1rem;
}

#page-admin .container main .users-list .infinite {
   padding-bottom: 23rem;
}

#page-admin .container main .users-list .infinite::-webkit-scrollbar {
   width: 0.7rem;
}

#page-admin .container main .users-list .infinite::-webkit-scrollbar-thumb {
   background: #AAA;
   border-radius: 1rem;
}

#page-admin .container main .users-list .infinite::-webkit-scrollbar-thumb:hover {
   background: #888;
}

#page-admin .container main .users-list .infinite-scroll-component__outerdiv {
   width: 100%;
}

#page-admin .container main .user-info {
   height: 4.5rem;
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   font: 500 1.3rem DM Sans;
   line-height: 2.2rem;
   color: #4F4F4F;
   background: var(--color-text-button);
   border: 0.72px solid #E6E6F0;
   border-radius: 1rem;
   padding: 0 2rem 0 3rem;
   margin-bottom: 1rem;
   outline: none;
   cursor: pointer;
}

#page-admin .user-name {
   width: 22rem;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

#page-admin .user-email {
   width: 22rem;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

#page-admin .user-cellphone {
   width: 13rem;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

#page-admin .user-platform {
   width: 8rem;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   display: flex;
   align-items: center;
   justify-content: center;
}

#page-admin .user-yesNo {
   width: 8rem;
   overflow: hidden;
   text-overflow: ellipsis;
   display: flex;
   align-items: center;
   justify-content: center;
}

#page-admin .user-createdAt {
   width: 10rem;
   overflow: hidden;
   text-overflow: ellipsis;
   display: flex;
   align-items: center;
   justify-content: center;
}
