#page-signup {
   width: 100vw;
   height: 100vh;
   display: grid;
   grid-template-rows: 1fr;
   grid-template-columns: 1fr;
   grid-template-areas: "form";
   background: #000;
   overflow: hidden;
}

#page-signup .image-logo {
   grid-area: form;
   background: url('../../../assets/imageSkate.svg') no-repeat round;
   -webkit-background-size: auto;
   -moz-background-size: auto;
   -o-background-size: auto;
   background-size: auto;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: flex-end;
}

#page-signup .image-logo .logo-container {
   width: 25rem;
   margin: 0 3.7rem 3rem 7.7rem;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
}

#page-signup .image-logo img {
   height: 12.4rem;
   width: 12.4rem;
   border-radius: 3rem;
   margin-right: 1.5rem;
}

#page-signup .image-logo .enjoy {
   font: 700 2.5rem DM Sans;
   line-height: 4.2rem;
   color: #FFF;
   text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#page-signup-content {
   grid-area: form;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: auto;
}

#page-signup-content .content {
   background: #FFF;
   padding: 5rem;
   border-radius: 0.8rem;
}

#page-signup-content .content h1 {
   font: 700 3rem Inter;
   line-height: 3.6rem;
   color: #000;
   margin-bottom: 1.2rem;
}

#page-signup-content .content h4 {
   font: 400 1.8rem Inter;
   line-height: 2.8rem;
   color: var(--color-h4-login);
   width: 40.9rem;
   margin-bottom: 2.2rem;
}

#page-signup-content .content h4 a {
   font: 400 1.8rem Inter;
   line-height: 2.8rem;
   color: var(--color-h4-login);
   text-decoration: none;
   text-decoration-line: underline;
}

#page-signup-content .content main form fieldset {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

#page-signup-content .content main label {
   font: 500 1.6rem Inter;
   line-height: 1.9rem;
   color: var(--color-input-label);
}

#page-signup-content .content main .input-block {
   margin: 0.6rem auto;
}

#page-signup-content .content main input {
   width: 42.4rem;
   height: 5.2rem;
   box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
   border-radius: 0.8rem;
   font: 500 1.4rem Inter;
   color: #494949;
   line-height: 1.7rem;
}

#page-signup-content .content .enter {
   display: flex;
   align-items: center;
   justify-content: center;
   text-decoration: none;
   outline: none;
   width: 42.4rem;
   height: 5.2rem;
   background: var(--color-button-submit);
   color: var(--color-text-button);
   font: 700 1.6rem DM Sans;
   line-height: 2.1rem;
   border-radius: 0.8rem;
   border: 0;
   margin-top: 2rem;
   transition: background-color 0.2s;
}

#page-signup-content .content .enter:hover {
   background: var(--color-button-submit-dark);
}

#page-signup-content .content .enter:disabled {
   cursor: not-allowed;
}

#page-signup-content .content .enter-loading {
   display: flex;
   align-items: center;
   justify-content: center;
   text-decoration: none;
   outline: none;
   width: 42.4rem;
   height: 5.2rem;
   background: var(--color-button-submit);
   color: var(--color-text-button);
   font: 700 1.6rem DM Sans;
   line-height: 2.1rem;
   border-radius: 0.8rem;
   border: 0;
   margin-top: 2rem;
   transition: background-color 0.2s;
   cursor: default;
}

#page-signup-content .reCaptcha {
   margin-top: 2rem;
}

#page-signup-content::-webkit-scrollbar {
   width: 1rem;
}

#page-signup-content::-webkit-scrollbar-thumb {
   background: #AAA;
   border-radius: 2rem;
}

#page-signup-content::-webkit-scrollbar-thumb:hover {
   background: #888;
}

#page-signup .flags-container {
   position: absolute;
   right: 8rem;
   top: 10rem;
}

@media(min-width: 1100px) {
   #page-signup {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 670px 1fr;
      grid-template-areas: "img form";
      background: var(--color-background);
   }
   #page-signup .image-logo {
      grid-area: img;
      align-items: flex-start;
   }
   #page-signup .image-logo .logo-container {
      margin: 3.5rem 2.3rem 0 4.8rem;
   }
   #page-signup .image-logo .logo-container img {
      height: 7.8rem;
      width: 7.8rem;
      border-radius: 1.9rem;
   }
   #page-signup .image-logo .logo-container .enjoy {
      font: 700 2rem DM Sans;
      line-height: 2.6rem;
      display: flex;
      align-items: center;
      text-align: left;
   }
   #page-signup-content {
      padding: 2rem 0;
   }

   #page-signup .flags-container {
      position: absolute;
      right: 5rem;
      top: 4rem;
   }
}

@media(max-height: 800px) {
   #page-signup-content {
      align-items: flex-start;
      margin-top: 3rem;
   }
}
