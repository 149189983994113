#modalAddUsers {
   width: 48.4rem;
}

#modalAddUsers .modal-body {
   height: 54.7rem;
   background: #E9EEF2;
   box-shadow: 5px 5px 20px -10px rgba(0, 0, 0, 0.4);
   border-radius: 0.5rem;
   display: flex;
   flex-direction: column;
   padding: 3rem;
   overflow: hidden;
}

#modalAddUsers .modal-body form {
   height: 54.7rem;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

#modalAddUsers .modal-body h2 {
   font: 700 2.4rem Inter;
   line-height: 3.1rem;
   color: #000;
}

#modalAddUsers .modal-body .input-block input {
   height: 6.4rem;
   margin-top: 1.7rem;
}

#modalAddUsers .modal-body .input-block label {
   font: 500 1.6rem Inter;
   line-height: 1.9rem;
   color: #696F79;
}

#modalAddUsers .modal-body .input-container {
   margin-top: -2.5rem;
   position: relative;
}

#modalAddUsers .modal-body .input-container .icon-container {
   width: 4rem;
   height: 4rem;
   position: absolute;
   display: flex;
   justify-content: center;
   align-items: center;
   right: 15px;
   top: 30px;
   cursor: pointer;
}

#modalAddUsers .modal-body .input-container .userAddContainer {
   flex: 1;
   width: 100%;
   height: 26rem;
   margin-top: 16px;
   overflow-y: scroll;
}

#modalAddUsers .modal-body .input-container .userAddContainer::-webkit-scrollbar {
   width: 0.7rem;
}

#modalAddUsers .modal-body .input-container .userAddContainer::-webkit-scrollbar-thumb {
   background: #AAA;
   border-radius: 1rem;
}

#modalAddUsers .modal-body .input-container .userAddContainer::-webkit-scrollbar-thumb:hover {
   background: #888;
}

#modalAddUsers .modal-body .input-container .userAddContainer .emailAddedContainer {
   background-color: #FCFCFF;
   width: 97%;
   height: 3.2rem;
   border-radius: 1rem;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   padding: 0 1.5rem;
   margin-top: 0.8rem;
   color: #9999AC;
   font: 400 1.6rem DM Sans;
   line-height: 2.1rem;
}

#modalAddUsers .modal-body .input-container .userAddContainer .emailAddedContainer svg {
   cursor: pointer;
   margin-right: 1rem;
}

#modalAddUsers .addUsersButton {
   width: 100%;
   height: 6.4rem;
   background: var(--color-button-submit);
   border-radius: 0.8rem;
   display: flex;
   justify-content: center;
   align-items: center;
   font: 700 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #FFF;
   transition: background-color 0.2s;
   margin-top: 1.7rem;
}

#modalAddUsers .addUsersButton:hover {
   background-color: var(--color-button-submit-dark);
}
