#rocket-animation {
   flex: 1;
   display: flex;
   justify-content: center;
   align-items: center;
}

.modal-content {
   background: transparent !important;
   border: 0 !important;
}

#rocket-animation .animation-container {
   width: 400px;
   height: 500px;
   background: #202020;
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-direction: column;
   z-index: 0;
   border-radius: 3rem;
   overflow: hidden;
}

#rocket-animation h1 {
   font: 700 3rem DM Sans;
   color: var(--color-first-go);
   margin-top: 3.5rem;
}
