#modalAddTasks {
   width: 48.4rem;
}

#modalAddTasks .modal-body {
   background: #E9EEF2;
   box-shadow: 5px 5px 20px -10px rgba(0, 0, 0, 0.4);
   border-radius: 0.5rem;
   display: flex;
   flex-direction: column;
   padding: 3rem;
}

#modalAddTasks .modal-body h2 {
   font: 700 3rem Inter;
   line-height: 3.6rem;
   color: #000;
}

#modalAddTasks .modal-body .input-block input {
   height: 5.5rem;
   margin-top: 0.5rem;
}

#modalAddTasks .modal-body .input-block label {
   font: 500 1.6rem Inter;
   line-height: 1.9rem;
   color: #696F79;
}

#modalAddTasks .modal-body .input-block .react-select-container {
   width: 100%;
   height: 5.5rem;
   margin-top: 0.5rem;
   border-radius: 0.8rem;
   background: var(--color-input-background);
   border: 1px solid var(--color-input-border);
   outline: none;
   padding: 0 1.6rem;
   font: 500 1.4rem Inter;
   text-transform: capitalize;
   color: #8692A6;
}

#modalAddTasks .modal-body .input-block .react-select__control {
   width: 100%;
   height: 5.5rem;
   border: 0;
   background-color: transparent;
}

#modalAddTasks .modal-body .input-block .react-select__value-container {
   width: 100%;
   height: 5.5rem;
   border: 0;
   background-color: transparent;
}

#modalAddTasks .modal-body .input-block .react-select__placeholder {
   color: #333333;
   text-transform: capitalize;
}

#modalAddTasks .modal-body .input-block .react-select__menu {
   width: 93%;
}

#modalAddTasks .modal-body .input-block .react-select__option:active {
   background-color: #6B2722;
   color: #FFF;
}

#modalAddTasks .modal-body .input-block .react-select__indicators {
   display: none;
}

#modalAddTasks .input-block .react-select-container:focus {
   border: 2px solid var(--color-button-submit);
}

#modalAddTasks .modal-body .react-datepicker {
   width: 100%;
   font: 500 1.2rem Inter;
   color: #333333;
}

#modalAddTasks .modal-body .react-datepicker button {
   outline: none;
}

#modelAddTasks .modal-body
.react-datepicker__month-container,
.react-datepicker__month {
   width: 17rem;
}

#modalAddTasks .modal-body .react-datepicker__current-month {
   font: 700 1.3rem Inter;
   color: #333333;
}

#modalAddTasks .modal-body .react-datepicker__day-names {
    font: 500 1rem Inter;
   color: #333333;
}

#modalAddTasks .modal-body .input-block .react-datepicker-wrapper {
   width: 100%;
   height: 5.5rem;
   margin-top: 0.5rem;
   border-radius: 0.8rem;
   background: var(--color-input-background);
   border: 1px solid var(--color-input-border);
   padding: 0 1.6rem;
   outline: none;
}

#modalAddTasks .modal-body .input-block .react-datepicker__input-container {
   display: flex;
   height: 100%;
   align-items: center;
}

#modalAddTasks .modal-body .input-block .react-datepicker-wrapper button {
   width: 100%;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   font: 500 1.4rem Inter;
   text-transform: capitalize;
   color: #333333;
   outline: none;
}

#modalAddTasks .modal-body .input-block .react-datepicker-wrapper button span {
   margin-left: 1rem;
}

#modalAddTasks .addTaskButton {
   width: 100%;
   height: 5.5rem;
   background: var(--color-button-submit);
   border-radius: 0.8rem;
   display: flex;
   justify-content: center;
   align-items: center;
   font: 700 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #FFF;
   transition: background-color 0.2s;
   margin-top: 1.7rem;
}

#modalAddTasks .addTaskButton:hover {
   background-color: var(--color-button-submit-dark);
}

#modalAddTasks .addTaskButtonDisabled {
   width: 100%;
   height: 5.5rem;
   background: var(--color-button-submit);
   border-radius: 0.8rem;
   display: flex;
   justify-content: center;
   align-items: center;
   font: 700 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #FFF;
   transition: background-color 0.2s;
   margin-top: 1.7rem;
   cursor: not-allowed;
}

#modalAddTasks .addTaskButtonWithDetails {
   width: 100%;
   height: 5.5rem;
   background: #97979A;
   border-radius: 0.8rem;
   display: flex;
   justify-content: center;
   align-items: center;
   font: 700 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #FFF;
   transition: background-color 0.2s;
   margin-top: 1.7rem;
}

#modalAddTasks .addTaskButtonWithDetails:hover {
   background-color: #878788;
}

#modalAddTasks .addTaskButtonWithDetailsDisabled {
   width: 100%;
   height: 5.5rem;
   background: #97979A;
   border-radius: 0.8rem;
   display: flex;
   justify-content: center;
   align-items: center;
   font: 700 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #FFF;
   transition: background-color 0.2s;
   margin-top: 1.7rem;
   cursor: not-allowed;
}
