:root {
   font-size: 40%;
   --color-background: #262626;
   --color-background-top: #262626;
   --color-background-bottom: #E9EEF2;
   --color-background-columns: #EBEBEB;
   --color-first-go: #E62631;
   --color-second-go: #A61D17;
   --color-thirdy-go: #732A24;
   --color-button-submit: #6B2722;
   --color-button-submit-dark: #571e1a;
   --color-button-google-facebook: #262626;
   --color-text-button: #FCFCFF;
   --color-text-placeholder: #91919F;
   --color-input-background: #FCFCFF;
   --color-input-label: #696F79;
   --color-input-border: #8692A6;
   --color-h4-login: #8692A6;
}

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

html, body, #root {
   height: 100vh;
}

body {
   background: var(--color-background);
}

/* #root {
   display: flex;
   align-items: center;
   justify-content: center;
} */

body, input, button, textarea {
   font: 500 1.6rem DM Sans;
}

.container {
   width: 90vw;
   max-width: 1500px;
}

@media (min-width: 1100px) {
   :root {
      font-size: 62.5%;
   }
}

.Toastify__toast-body {
    font: 500 1.4rem DM Sans;
    line-height: 1.8rem;
    padding: 0.8rem;
}
