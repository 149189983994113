#modalTasksInGoGoGo {
   width: 48.4rem;
}

#modalTasksInGoGoGo .modal-body {
   height: 59.5rem;
   background: #E9EEF2;
   box-shadow: 5px 5px 20px -10px rgba(0, 0, 0, 0.4);
   border-radius: 0.5rem;
   display: flex;
   flex-direction: column;
   padding: 3rem;
}

#modalTasksInGoGoGo .modal-body h2 {
   font: 700 3rem DM Sans;
   line-height: 3.6rem;
   color: #EB6A6A;
}

#modalTasksInGoGoGo .modal-body .subtitle {
   font: 700 2.1rem Inter;
   line-height: 2.6rem;
   color: #4F4F4F;
}

#modalTasksInGoGoGo .modal-body .input-block label {
   font: 500 1.6rem Inter;
   line-height: 1.9rem;
   color: #696F79;
   margin-top: 1rem;
}

#modalTasksInGoGoGo .modal-body .input-block .react-select-container {
   width: 100%;
   height: 6.4rem;
   margin-top: 1.6rem;
   border-radius: 0.8rem;
   background: var(--color-input-background);
   border: 1px solid var(--color-input-border);
   outline: none;
   padding: 0 1.6rem;
   font: 500 1.4rem Inter;
   line-height: 1.7rem;
   color: #8692A6;
}

#modalTasksInGoGoGo .modal-body .input-block .react-select__control {
   width: 100%;
   height: 6.4rem;
   border: 0;
   background-color: transparent;
}

#modalTasksInGoGoGo .modal-body .input-block .react-select__value-container {
   width: 100%;
   height: 6.4rem;
   border: 0;
   background-color: transparent;
}

#modalTasksInGoGoGo .modal-body .input-block .react-select__placeholder {
   color: #333333;
}

#modalTasksInGoGoGo .modal-body .input-block .react-select__menu {
   width: 93%;
}

#modalTasksInGoGoGo .modal-body .input-block .react-select__option:active {
   background-color: #6B2722;
   color: #FFF;
}

#modalTasksInGoGoGo .modal-body .input-block .react-select__indicators {
   display: none;
}

#modalTasksInGoGoGo .input-block .react-select-container:focus {
   border: 2px solid var(--color-button-submit);
}

#modalTasksInGoGoGo .modal-body .input-block textarea {
   width: 100%;
   height: 17.3rem;
   margin-top: 1.6rem;
   border-radius: 0.8rem;
   background: var(--color-input-background);
   border: 1px solid var(--color-input-border);
   outline: none;
   padding: 1.6rem;
   font: 500 1.4rem Inter;
   line-height: 1.7rem;
   color: #8692A6;
}

#modalTasksInGoGoGo .input-block textarea:focus {
   border: 2px solid var(--color-button-submit);
}

.input-block textarea::placeholder {
   color: var(--color-text-placeholder);
   font: 500 1.4rem Inter;
   line-height: 1.7rem;
}

#modalTasksInGoGoGo .gogogoButton {
   width: 100%;
   height: 6.4rem;
   background: var(--color-button-submit);
   border-radius: 0.8rem;
   display: flex;
   justify-content: center;
   align-items: center;
   font: 700 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #FFF;
   transition: background-color 0.2s;
   /* margin-top: 2.8rem; */
}

#modalTasksInGoGoGo .gogogoButton:hover {
   background-color: var(--color-button-submit-dark);
}
