#modalSendMotivation {
   width: 48.4rem;
}

#modalSendMotivation .modal-body {
   background: #E9EEF2;
   box-shadow: 5px 5px 20px -10px rgba(0, 0, 0, 0.4);
   border-radius: 0.5rem;
   display: flex;
   flex-direction: column;
   padding: 3rem;
}

#modalSendMotivation .modal-body h2 {
   font: 700 3rem Inter;
   line-height: 3.6rem;
   color: #6B2722;
   margin-bottom: 2.5rem;
}

#modalSendMotivation .modal-body .btn-incentives {
   width: 100%;
   height: 6.4rem;
   background: #FFFFFF;
   border: 1px solid #6B2722;
   border-radius: 6px;
   box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
   display: flex;
   justify-content: flex-start;
   align-items: center;
   padding: 0 2.8rem;
   margin-bottom: 1rem;
   font: 400 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #91919F;
   text-align: left;
   transition: background-color 0.2s;
}

#modalSendMotivation .modal-body .btn-incentives:hover {
   background-color: #f0f0f0;
   color: #6B2722;
}

#modalSendMotivation .modal-body .btn-incentive-personalized {
   width: 100%;
   height: 6.4rem;
   background: #FFFFFF;
   border: 1px solid #6B2722;
   border-radius: 6px;
   box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
   display: flex;
   justify-content: flex-start;
   align-items: center;
   padding: 0 2.8rem;
   margin-top: 3rem;
   font: 400 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #91919F;
   text-align: left;
   transition: background-color 0.2s;
}

#modalSendMotivation .modal-body .btn-incentive-personalized:hover {
   background-color: rgba(107, 39, 34, 0.18);
   color: #6B2722;
}

#modalSendMotivation .modal-body .input-block textarea {
   width: 100%;
   height: 36.9rem;
   border-radius: 0.6rem;
   background: var(--color-input-background);
   border: 1px solid var(--color-input-border);
   outline: none;
   padding: 1.6rem;
   font: 500 1.4rem Inter;
   line-height: 1.7rem;
   color: #000000;
}

#modalSendMotivation .input-block textarea:focus {
   border: 2px solid var(--color-button-submit);
}

#modalSendMotivation .input-block textarea::placeholder {
   color: var(--color-text-placeholder);
   font: 500 1.4rem Inter;
   line-height: 1.7rem;
}

#modalSendMotivation .btn-send-personalized {
   width: 100%;
   height: 6.4rem;
   background: var(--color-button-submit);
   border-radius: 0.8rem;
   display: flex;
   justify-content: center;
   align-items: center;
   font: 700 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #FFF;
   margin-top: 4rem;
   transition: background-color 0.2s;
}

#modalSendMotivation .btn-send-personalized:hover {
   background-color: var(--color-button-submit-dark);
}

#modalSendMotivation .btn-send-personalized:disabled {
   cursor: default;
}
