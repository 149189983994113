#modalTasksChangeType {
   width: 48.4rem;
}

#modalTasksChangeType .modal-body {
   height: 36.4rem;
   background: #E9EEF2;
   box-shadow: 5px 5px 20px -10px rgba(0, 0, 0, 0.4);
   border-radius: 0.5rem;
   display: flex;
   justify-content: space-between;
   flex-direction: column;
   padding: 3rem;
}

#modalTasksChangeType .modal-body h2 {
   font: 700 3rem Inter;
   line-height: 3.6rem;
   color: #000000;
   margin: 0;
}

#modalTasksChangeType .modal-body .subtitle {
   font: 400 1.6rem Inter;
   line-height: 1.9rem;
   color: #696F79;
}

#modalTasksChangeType .change-type-button {
   width: 100%;
   height: 6.4rem;
   background: var(--color-button-submit);
   border-radius: 0.8rem;
   display: flex;
   justify-content: center;
   align-items: center;
   font: 700 1.8rem DM Sans;
   line-height: 2.3rem;
   color: #FFF;
   transition: background-color 0.2s;
}

#modalTasksChangeType .change-type-button:hover {
   background-color: var(--color-button-submit-dark);
}
