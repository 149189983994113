#modalDeleteConfirm {
   width: 48.4rem;
   background-color: transparent;
}

#modalDeleteConfirm .modal-body {
   background: #FFF;
   box-shadow: 20px 0px 40px 1px rgba(0, 0, 0, 0.4);
   display: flex;
   flex-direction: column;
   overflow: hidden;
}

#modalDeleteConfirm .modal-body h2 {
   font: 700 2.8rem DM Sans;
   line-height: 3.6rem;
   color: #000;
}

#modalDeleteConfirm .modal-body p {
   font: 400 1.8rem DM Sans;
   line-height: 2.3rem;
   color: #6B2722;
   margin-top: 2rem;
}

#modalDeleteConfirm .modal-body p strong {
   font: 700 1.8rem DM Sans;
   line-height: 2.3rem;
   color: #6B2722;
}

#modalDeleteConfirm .buttonContainer {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   margin-top: 2rem;
}

#modalDeleteConfirm .buttonContainer .buttonConfirm {
   width: 100%;
   height: 6.4rem;
   background: #BDBDBD;
   border-radius: 0.8rem;
   display: flex;
   justify-content: center;
   align-items: center;
   font: 700 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #FFF;
   margin-top: 1.7rem;
   transition: background-color 0.2s;
   outline: none;
}

#modalDeleteConfirm .buttonContainer .buttonConfirm:hover {
   background-color: var(--color-input-label)
}

#modalDeleteConfirm .buttonContainer .buttonCancel {
   width: 100%;
   height: 6.4rem;
   background: var(--color-button-submit);
   border-radius: 0.8rem;
   display: flex;
   justify-content: center;
   align-items: center;
   font: 700 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #FFF;
   margin-top: 1.7rem;
   transition: background-color 0.2s;
   outline: none;
}

#modalDeleteConfirm .buttonContainer .buttonCancel:hover {
   background-color: var(--color-button-submit-dark)
}
