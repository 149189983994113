#page-login {
   width: 100vw;
   height: 100vh;
   display: grid;
   grid-template-rows: 1fr;
   grid-template-columns: 1fr;
   grid-template-areas: "form";
   background: #000;
   overflow: hidden;
}

#page-login .image-logo {
   grid-area: form;
   background: url('../../../assets/imageMontain.svg') no-repeat round;
   -webkit-background-size: auto;
   -moz-background-size: auto;
   -o-background-size: auto;
   background-size: auto;
   height: 100vh;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: flex-end;
}

#page-login .image-logo img {
   height: 12.4rem;
   width: 12.4rem;
   margin: 0 3.7rem 5.6rem 7.7rem;
   border-radius: 3rem;
}

#page-login .image-logo span {
   font: 700 3.2rem DM Sans;
   line-height: 4.2rem;
   color: #FFF;
   text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   width: 17.6rem;
   height: 11.4rem;
   margin-bottom: 6.5rem;
}

#page-login-content {
   grid-area: form;
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: scroll;
}

#page-login-content .content {
   background: #FFF;
   padding: 5rem;
   border-radius: 0.8rem;
   margin-bottom: 3rem;
}

#page-login-content .content h1 {
   font: 700 3rem Inter;
   line-height: 3.6rem;
   color: #000;
   margin-bottom: 1.2rem;
}

#page-login-content .content h4 {
   font: 400 1.8rem Inter;
   line-height: 2.8rem;
   color: var(--color-h4-login);
   width: 40.9rem;
   margin-bottom: 1rem;
}

#page-login-content .content main label {
   font: 500 1.6rem Inter;
   line-height: 1.9rem;
   color: var(--color-input-label);
}

#page-login-content .content main input {
   width: 42.4rem;
   height: 5.2rem;
   box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
   border-radius: 0.8rem;
   font: 500 1.4rem Inter;
   color: #494949;
   line-height: 1.7rem;
}

#page-login-content .content .enter {
   display: flex;
   align-items: center;
   justify-content: center;
   text-decoration: none;
   outline: none;
   width: 42.4rem;
   height: 5.2rem;
   background: var(--color-button-submit);
   color: var(--color-text-button);
   font: 700 1.6rem DM Sans;
   line-height: 2.1rem;
   border-radius: 0.8rem;
   border: 0;
   margin: 3rem 0 1.5rem;
   transition: background-color 0.2s;
}

#page-login-content .content .enter:hover {
   background: var(--color-button-submit-dark);
}

#page-login-content .content .facebookButton {
   display: flex;
   align-items: center;
   justify-content: center;
   text-decoration: none;
   width: 42.4rem;
   height: 5.2rem;
   border-radius: 0.8rem;
   background: #FFF;
   border: 0;
   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
   font: 700 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #000;
   margin-bottom: 1.5rem;
   outline: none;
   transition: background-color 0.2s;
   cursor: pointer;
}

#page-login-content .content .facebookButton:hover {
   background-color: rgba(0, 0, 0, 0.08);
}

#page-login-content .content .facebookButton svg {
   position: relative;
   right: 9rem;
}

#page-login-content .content .googleButton {
   display: flex;
   align-items: center;
   justify-content: center;
   text-decoration: none;
   width: 42.4rem;
   height: 5.2rem;
   border-radius: 0.8rem;
   background: #000;
   border: 0;
   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
   font: 700 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #FFF;
   outline: none;
   transition: background-color 0.2s;
   cursor: pointer;
}

#page-login-content .content .googleButton:hover {
   background-color: rgba(0, 0, 0, 0.9);
}

#page-login-content .content .googleButton svg {
   position: relative;
   right: 10rem;
}

#page-login-content .content .footer {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-top: 2.5rem;
}

#page-login-content .content .footer a {
   color: var(--color-text-placeholder);
   font: 400 1.4rem DM Sans;
   line-height: 1.8rem;
   text-decoration: none;
   margin-bottom: 1.2rem
}

#page-login-content .content .footer span {
   color: var(--color-text-placeholder);
   font: 400 1.4rem DM Sans;
}

#page-login-content .content .footer span strong {
   color: var(--color-text-placeholder);
   font: 400 1.4rem DM Sans;
   text-decoration-line: underline;
}

#page-login-content::-webkit-scrollbar {
   width: 1rem;
}

#page-login-content::-webkit-scrollbar-thumb {
   background: #AAA;
   border-radius: 2rem;
}

#page-login-content::-webkit-scrollbar-thumb:hover {
   background: #888;
}

#page-login .flags-container {
   position: absolute;
   right: 8rem;
   top: 10rem;
}

@media(min-width: 1100px) {
   #page-login {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 670px 1fr;
      grid-template-areas: "img form";
      background: var(--color-background);
   }
   #page-login .image-logo {
      grid-area: img;
   }
   #page-login .image-logo img {
      height: 7.8rem;
      width: 7.8rem;
      margin: 0 2.3rem 3.5rem 4.8rem;
      border-radius: 1.9rem;
   }
   #page-login .image-logo span {
      font: 700 2rem DM Sans;
      line-height: 2.6rem;
      width: 11rem;
      height: 7.1rem;
      margin-bottom: 4.5rem;
   }

   #page-login .flags-container {
      position: absolute;
      right: 5rem;
      top: 4rem;
   }
}

@media(max-height: 700px) {
   #page-login-content {
      align-items: flex-start;
      margin: 3rem 0;
   }
}
