button {
   border: 0;
   background: transparent;
}

button .brFlag {
   height: 5rem;
   width: 5rem;
   margin-right: 1.5rem;
}

button .usFlag {
   height: 5rem;
   width: 5rem;
}

@media(min-width: 1100px) {
   button .brFlag {
      height: 3.5rem;
      width: 3.5rem;
   }

   button .usFlag {
      height: 3.5rem;
      width: 3.5rem;
   }
}
