#page-notifications {
   width: 100vw;
   height: 100vh;
   overflow: auto;
   overflow-x: hidden;
   background-color: #262626;
}

#page-notifications .container {
   height: calc(100% - 13rem);
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

#page-notifications .container .header-container {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   padding: 1.5rem 2.8rem;
   border-bottom: 1px solid #D5D5D2;
}

#page-notifications .container main {
   flex: 1;
   display: flex;
   align-items: flex-start;
   overflow: hidden;
   margin-top: 2.8rem;
}

#page-notifications .container main .column {
   background: var(--color-background-columns);
   width: 68.4rem;
   /* max-height: 100%; */
   height: 100%;
   box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.4);
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   border-radius: 0.5rem;
   border-bottom: 1px solid #D4D4D2;
   padding-bottom: 2.8rem;
}

#page-notifications .container .column .header-container h1 {
   font: 700 2rem DM Sans;
   line-height: 2.6rem;
   color: #000;
}

#page-notifications .container main .column-two-parts {
   flex: 1;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   padding-top: 1.5rem;
   overflow-y: scroll;
}

#page-notifications .container main .column-two-parts::-webkit-scrollbar {
   width: 0.7rem;
}

#page-notifications .container main .column-two-parts::-webkit-scrollbar-thumb {
   background: #AAA;
   border-radius: 1rem;
}

#page-notifications .container main .column-two-parts::-webkit-scrollbar-thumb:hover {
   background: #888;
}

#page-notifications .container .column-two-parts .no-notification {
   margin-top: 4rem;
}

#page-notifications .container .column-two-parts .no-notification h1 {
   font: 700 2rem DM Sans;
   line-height: 2.1rem;
   color: #000;
}

#page-notifications .container .column-two-parts .card-notification {
   width: 63.1rem;
   height: 6.5rem;
   border-radius: 1rem;
   background: #FCFCFF;
   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 0.8rem;
   padding: 1.5rem 1.5rem 1rem 1.4rem;
}

#page-notifications .container .column-two-parts .card-notification .card-content {
   width: 50rem;
   height: 6rem;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   text-decoration: none;
   /* padding: 0.7rem 0; */
}

#page-notifications .container .column-two-parts .card-notification .card-content h1 {
   width: 53rem;
   font: 700 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #6B2722;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

#page-notifications .container .column-two-parts .card-notification .card-content h3 {
   width: 55rem;
   font: 400 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #979494;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

#page-notifications .container .column-two-parts .card-notification .date-container {
   height: 6rem;
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   align-items: flex-end;
   margin-bottom: 1rem;
}

#page-notifications .container .column-two-parts .card-notification .date-container span {
   font: 400 1.2rem DM Sans;
   line-height: 1.6rem;
   color: #B0B0B0;
}

#page-notifications .container .column-two-parts .button-load-more {
   width: 63.1rem;
   background-color: #B1B1B1;
   border-radius: 1rem;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 1.7rem;
   padding: 1.5rem;
   outline: none;
   font: 400 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #ECECEC;
}
