#page-config {
   width: 100vw;
   height: 100vh;
   overflow: auto;
   overflow-x: hidden;
   background-color: #262626;
}

#page-config .container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

#page-config .container .flags-container {
   position: absolute;
   right: 8rem;
   top: 9rem;
}

#page-config .container .main-container {
   width: 74.1rem;
   height: 43.6rem;
  background: var(--color-background-columns);
   box-shadow: 0 1.5rem 1rem -1.5rem rgba(0, 0, 0, 0.4);
   border-radius: 0.5rem;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-start;
   margin-top: 2.8rem;
}

#page-config .container .main-container .header-name {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   margin-top: 2.6rem;
   padding-left: 3.5rem;
}

#page-config .container .main-container .header-name h1 {
   font: 700 2rem DM Sans;
   line-height: 2.6rem;
   text-transform: capitalize;
   color: #161719;
}

#page-config .container .main-container .content {
   width: 85%;
   height: 27rem;
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   align-items: flex-start;
}

#page-config .container .main-container .content .avatar-container {
   height: 25.8rem;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
}

#page-config .container .main-container .content .avatar-container .button-save-avatar {
   width: 14rem;
   height: 3.6rem;
   background: #6B2722;
   box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
   border-radius: 0.6rem;
   color: #fff;
   font: 400 1.4rem DM Sans;
   line-height: 1.8rem;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 2rem;
   outline: none;
}

#page-config .container .main-container .content .avatar-container .button-save-avatar:hover {
   background: #571e1a;
}

#page-config .container .main-container .content .avatar-container .change-avatar-text {
   margin-top: 1.5rem;
   font-family: DM Sans;
   font-size: 1rem;
   font-weight: 400;
   color: #000;
}

.avatar {
   width: 15.7rem;
   height: 15.7rem;
   border-radius: 7.85rem;
   background: #F9F9F9;
   box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.7);
   vertical-align: middle;
   overflow: hidden;
   transition: linear 0.25s;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #FFF;
   font: 700 5rem DM Sans;
   line-height: 0;
   cursor: pointer;
   outline: none;
}

.avatar .drop-here {
   color: #FFF;
   font: 400 1.4rem DM Sans;
   line-height: 0;
}

.avatar img {
   border-radius: 7.85rem;
   width: 15.7rem;
   height: 15.7rem;
   object-fit: cover;
   vertical-align: middle;
   overflow: hidden;
}

#page-config .container .main-container .content .options-container {
   width: 24.8rem;
   height: 18.8rem;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: flex-start;
}

#page-config .container .main-container .content .options-container .options {
   width: 24.8rem;
   height: 3.4rem;
   border-radius: 0.5rem;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   padding-left: 1.5rem;
   outline: none;
   text-decoration: none;
}

#page-config .container .main-container .content .options-container .options:hover {
   background: #c2c2c2;
}

#page-config .container .main-container .content .options-container .options span {
   font: 500 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #2E2E2E;
   margin-left: 2.4rem;
}

#page-config .container .main-container .content .options-container .options-logout {
   width: 24.8rem;
   height: 3.4rem;
   border-radius: 0.5rem;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   padding-left: 1.5rem;
   outline: none;
}

#page-config .container .main-container .content .options-container .options-logout:hover {
   background: #C1C5D8;
}

#page-config .container .main-container .content .options-container .options-logout .logout {
   font: 500 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #999898;
   margin-left: 2.4rem;
}

#page-config .container .main-container .content .options-container .options .logout:hover {
   background: #C1C5D8;
}

#page-config .container .main-container .footer {
   width: 69.5rem;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 0 2.3rem 2.1rem 2.3rem;
   font: 400 1.2rem DM Sans;
   line-height: 1.6rem;
   color: #888686;
}

@media(min-width: 1550px) {
   #page-config .container .flags-container {
      position: absolute;
      right: 8rem;
      top: 3.5rem;
   }
}
