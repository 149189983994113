#page-teams {
   width: 100vw;
   height: 100vh;
   overflow: auto;
   overflow-x: hidden;
   background-color: #262626;
}

#page-teams .container {
   height: calc(100% - 11rem);
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

#page-teams .container .header {
   background: #F6F8F9;
   width: 68.4rem;
   height: 5.6rem;
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-radius: 0.8rem 0.8rem 0 0;
   border-bottom: 1px solid #D4D4D2;
   padding: 1.2rem 2.8rem;
   margin-top: 2.8rem;
}

#page-teams .container .header h1 {
   font: 700 2rem DM Sans;
   line-height: 2.6rem;
   color: #000;
}

#page-teams .container .header .button-add-team {
   width: 14.2rem;
   height: 3.3rem;
   background-color: #6B2722;
   border-radius: 0.6rem;
   font: 700 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #FCFCFF;
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   align-items: center;
   outline: none;
   transition: background-color 0.2s;
}

#page-teams .container .header .button-add-team:hover {
   background-color: var(--color-button-submit-dark);
}

#page-teams .container main {
   flex: 1;
   width: 68.4rem;
   min-height: 40rem;
   border-radius: 0 0 0.8rem 0.8rem;
   display: flex;
   flex-direction: column;
   align-items: center;
   background: #F6F8F9;
   margin-bottom: 3rem;
   box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.4);
   padding-bottom: 3rem;
   position: static;
}

#page-teams .container main .scroll {
   flex: 1;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   overflow-y: scroll;
}

#page-teams .container main .scroll::-webkit-scrollbar {
   width: 0.7rem;
}

#page-teams .container main .scroll::-webkit-scrollbar-thumb {
   background: #AAA;
   border-radius: 1rem;
}

#page-teams .container main .scroll::-webkit-scrollbar-thumb:hover {
   background: #888;
}

#page-teams .container .no-teams {
   margin-top: 4rem;
}

#page-teams .container .no-teams h1 {
   font: 700 2rem DM Sans;
   line-height: 2.1rem;
   color: #000;
}

#page-teams .accordionContainer {
   margin-top: 3.2rem;
}

#page-teams .container .card-team {
   width: 60.8rem;
   height: 5.8rem;
   background: #FCFCFF;
   border-radius: 1rem;
   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 0.8rem;
   padding: 0 2.3rem;
}

#page-teams .container .card-small-team {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   width: 42rem;
   height: 5.8rem;
   cursor: pointer;
}

#page-teams .container .card-team {
   display: flex;
   align-items: center;
}

#page-teams .container .card-team h1 {
   font: 500 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #4F4F4F;
   text-transform: capitalize;
   margin-right: 2rem;
}

#page-teams .container .card-team .pill-team-status {
   width: 7.2rem;
   height: 1.6rem;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 3rem;
   font: 400 1.2rem DM Sans;
   line-height: 1.6rem;
   color: #FCFFFC;
}

#page-teams .container .card-small-team .link-team-tasks svg {
   transition: opacity 0.2s;
}

#page-teams .container .card-team .link-team-tasks svg:hover {
   opacity: 0.5;
}

#page-teams .container .card-small-team .link-team-dashboard svg {
   transition: opacity 0.2s;
}

#page-teams .container .card-team .link-team-dashboard svg:hover {
   opacity: 0.5;
}

#page-teams .container .card-team .cogButton {
   width: 4.5rem;
   height: 4.5rem;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: 0;
   cursor: pointer;
   transition: opacity 0.2s;
}

#page-teams .container .card-team .cogButton svg:hover {
   opacity: 0.5;
}

#page-teams .container .card-team .cogButton svg {
   color: #97979A;
   width: 2.25rem;
   height: 2.25rem;
}

#page-teams .container .dropdown {
   position: relative;
   display: inline-block;
}

#page-teams .container .menu {
   position: absolute;
   display: block;
   height: 16.8rem;
   width: 21.9rem;
   filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.4));
   overflow: hidden;
   z-index: 1;
   right: 1rem;
   top: 4rem;
}

#page-teams .container .menu-dots {
   position: absolute;
   display: block;
   width: 21.9rem;
   filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.4));
   overflow: hidden;
   z-index: 1;
   right: 2.8rem;
   top: 0.7rem;
}

#page-teams .container .menu button, #page-teams .container .menu-dots button, #page-teams .container .menu a, #page-teams .container .menu-motivations button {
   width: 100%;
   height: 4.2rem;
   background-color: #E9EEF2;
   display: flex;
   flex-direction: column;
   justify-content: center;
   text-align: start;
   font: 400 1.2rem DM Sans;
   line-height: 1.6rem;
   color: #000;
   border-bottom: 2px solid #FFF;
   outline: none;
   padding: 0 1.3rem;
   transition: background-color 0.2s;
   text-decoration: none;
}

#page-teams .container .menu button:hover, #page-teams .container .menu-dots button:hover, #page-teams .container .menu a:hover, #page-teams .container .menu-motivations button:hover {
   background-color: #d7dce0;
}

#page-teams .container .accordionCollapse {
   margin-bottom: 2.4rem;
   z-index: 0;
}

#page-teams .container .contentAccordion {
   width: 60.8rem;
   height: 7.4rem;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   border-bottom: 1px solid #FFF;
   filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
   padding: 1.5rem;
}

#page-teams .container .contentAccordion .avatarNameStatusContainer {
   width: 70%;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: flex-start;
   margin-left: 1.5rem;
}

#page-teams .container .contentAccordion .avatarNameStatusContainer .avatar {
   width: 4.5rem;
   height: 4.5rem;
   border-radius: 2.25rem;
   cursor: default;
}

#page-teams .container .contentAccordion .avatarNameStatusContainer .avatar-container {
   width: 4.5rem;
   height: 4.5rem;
   border: 1px solid #FFF;
   border-radius: 2.25rem;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.4));
   overflow: hidden;
   background: #F5F4F4;
}

#page-teams .container .contentAccordion .avatarNameStatusContainer .noAvatar {
   border: 1px solid #FFF;
   height: 4.5rem;
   width: 4.5rem;
   border-radius: 2.25rem;
   box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
   display: flex;
   justify-content: center;
   align-items: center;
   color: #FFF;
   font: 700 1.6rem DM Sans;
   text-decoration: none;
   overflow: hidden;
   vertical-align: middle;
}

#page-teams .container .contentAccordion .avatarNameStatusContainer .nameStatusContainer {
   margin-left: 2.3rem;
}

#page-teams .container .contentAccordion .avatarNameStatusContainer .nameStatusContainer h2 {
   font: 500 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #4F4F4F;
   text-transform: capitalize;
   margin-bottom: 0.8rem;
}

#page-teams .container .contentAccordion .avatarNameStatusContainer .nameStatusContainer .statusGoLate {
   background-color: #C00000;
   width: 7.2rem;
   height: 1.6rem;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 3rem;
   font: 400 1.2rem DM Sans;
   line-height: 1.6rem;
   color: #FFFFFF;
}

#page-teams .container .contentAccordion .avatarNameStatusContainer .nameStatusContainer .statusGoUpLate {
   background-color: #FF9867;
   width: 7.2rem;
   height: 1.6rem;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 3rem;
   font: 400 1.2rem DM Sans;
   line-height: 1.6rem;
   color: #FFFFFF;
}

#page-teams .container .contentAccordion .avatarNameStatusContainer .nameStatusContainer .statusGoTarget {
   background-color: #FEFF66;
   width: 7.2rem;
   height: 1.6rem;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 3rem;
   font: 400 1.2rem DM Sans;
   line-height: 1.6rem;
   color: #000000;
}

#page-teams .container .contentAccordion .avatarNameStatusContainer .nameStatusContainer .statusGoTop {
   background-color: #CAFF64;
   width: 7.2rem;
   height: 1.6rem;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 3rem;
   font: 400 1.2rem DM Sans;
   line-height: 1.6rem;
   color: #000000;
}

#page-teams .container .contentAccordion .avatarNameStatusContainer .nameStatusContainer .statusGoUpTarget {
   background-color: #02CB60;
   width: 7.2rem;
   height: 1.6rem;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 3rem;
   font: 400 1.2rem DM Sans;
   line-height: 1.6rem;
   color: #FFFFFF;
}

#page-teams .container .contentAccordion .avatarNameStatusContainer .nameStatusContainer .statusGoUpTop {
   background-color: #FF32CB;
   width: 7.2rem;
   height: 1.6rem;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 3rem;
   font: 400 1.2rem DM Sans;
   line-height: 1.6rem;
   color: #FFFFFF;
}

#page-teams .container .contentAccordion .motivation-container {
   height: 4rem;
   display: flex;
   justify-content: flex-end;
   align-items: flex-end;
}

#page-teams .container .contentAccordion .motivation-container .menu-motivations {
   outline: none;
   position: absolute;
   display: block;
   width: 21.9rem;
   filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.4));
   overflow: hidden;
   z-index: 1;
   left: 62rem;
   top: 2rem;
}

#page-teams .container .contentAccordion .motivation-container .send {
   outline: 0;
}

#page-teams .container .contentAccordion .motivation-container .send:hover {
   opacity: 0.5;
}

#page-teams .container .contentAccordion .configContainer {
   width: 10rem;
   flex-direction: column;
   display: flex;
   justify-content: flex-start;
   align-items: flex-end;
   height: 7.4rem;
   padding: 0.5rem;
}

#page-teams .container .contentAccordion .configContainer .dots {
   outline: 0;
}

#page-teams .container .contentAccordion .configContainer .adminContainer {
   height: 3rem;
   display: flex;
   align-items: center;
   justify-content: center;
}

#page-teams .container .contentAccordion .configContainer .adminContainer .adminPill {
   background: #FFFFFF;
   width: 7.7rem;
   height: 1.6rem;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 3rem;
   margin-right: 0.9rem;
   font: 400 1rem DM Sans;
   line-height: 1.3rem;
   color: #676768;
}
