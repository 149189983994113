#modalEditTeamName {
   width: 48.4rem;
}

#modalEditTeamName .modal-body {
   height: 33.7rem;
   background: #E9EEF2;
   box-shadow: 5px 5px 20px -10px rgba(0, 0, 0, 0.4);
   border-radius: 0.5rem;
   display: flex;
   flex-direction: column;
   padding: 3rem;
   overflow: hidden;
}

#modalEditTeamName .modal-body form {
   height: 54.7rem;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

#modalEditTeamName .modal-body h2 {
   font: 700 2.4rem Inter;
   line-height: 3.1rem;
   color: #000;
}

#modalEditTeamName .modal-body .input-block input {
   height: 6.4rem;
   margin-top: 1.7rem;
   text-transform: capitalize;
}

#modalEditTeamName .modal-body .input-block label {
   font: 500 1.6rem Inter;
   line-height: 1.9rem;
   color: #696F79;
}

#modalEditTeamName .addTeamButton {
   width: 100%;
   height: 6.4rem;
   background: var(--color-button-submit);
   border-radius: 0.8rem;
   display: flex;
   justify-content: center;
   align-items: center;
   font: 700 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #FFF;
   transition: background-color 0.2s;
   margin-top: 1.7rem;
}

#modalEditTeamName .addTeamButton:hover {
   background-color: var(--color-button-submit-dark);
}
