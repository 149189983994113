.input-block {
   position: relative;
   display: flex;
   flex-direction: column;
   margin: 2rem auto;
}

.input-block+.input-block {
   margin-top: 0.6rem;
}

.input-block label {
   font-size: 2rem;
   color: #FFF;
}

.input-block input {
   width: 100%;
   height: 5.2rem;
   margin-top: 0.8rem;
   border-radius: 0.8rem;
   background: var(--color-input-background);
   border: 1px solid var(--color-input-border);
   outline: none;
   padding: 0 1.6rem;
   font-size: 1.7rem;
}

.input-block input::placeholder {
   color: var(--color-text-placeholder);
   font: 500 1.4rem Inter;
   line-height: 1.7rem;
}

.input-block input:-webkit-autofill {
   -webkit-box-shadow: 0 0 0px 1000px var(--color-input-background) inset !important;
   box-shadow: 0 0 0px 1000px var(--color-input-background) inset !important;
}

.input-block input:focus {
   border: 2px solid var(--color-button-submit);
}

.input-block:focus-within::after {
   width: calc(100% - 2.5rem);
   height: 1px;
   content: '';
   background: var(--color-button-submit);
   position: absolute;
   left: 1.6rem;
   right: 1.6rem;
   bottom: 1rem;
}

