#page-header {
   width: 100vw;
   height: 11rem;
   display: flex;
   flex-direction: row;
   background: var(--color-background-top);
   overflow: hidden;
}

#page-header .top-bar-container {
   width: 100vw;
   padding: 0 4rem;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

#page-header .logo-and-focus-container {
   display: flex;
   flex-direction: row;
   align-items: center;
}

#page-header .top-bar-container .logo {
   width: 9.8rem;
   height: 9.8rem;
   border-radius: 1.8rem;
   margin-left: -5rem;
}

#page-header .top-bar-container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}

#page-header .top-bar-container .focus-actived-container {
   width: 28.8rem;
   height: 5.9rem;
   background: #262626;
   box-shadow: 0 1.5rem 1rem -1.5rem rgba(0, 0, 0, 0.4);
   border-radius: 0.5rem;
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   align-items: center;
   margin-left: 5rem;
}

#page-header .top-bar-container .focus-actived-container .clock-container {
   width: 10.2rem;
   height: 3.6rem;
   background: #FFFFFF;
   box-shadow: 0 4rem 4rem -2rem rgba(0, 0, 0, 0.4);
   border-radius: 1rem;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
}

#page-header .top-bar-container .focus-actived-container .clock-container span {
   font: 400 2.4rem DM Sans;
   line-height: 3.13rem;
   align-items: center;
   color: #4F4F4F;
}

#page-header .top-bar-container .focus-actived-container .progress-container {
   width: 14.4rem;
   height: 1.6rem;
   background: #FFFFFF;
   box-shadow: 0 4rem 4rem -2rem rgba(0, 0, 0, 0.4);
   border-radius: 1rem;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   padding: 0.3rem;
}

#page-header .top-bar-container .focus-actived-container .progress-container .progress {
   background: #4CD964;
   border-radius: 1rem;
}

#page-header .top-bar-container .top-bar-right {
   width: 80%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}

#page-header .top-bar-container .top-bar-right-on-focus {
   width: 35rem;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}

#page-header .top-bar-container .link-container {
   width: 20rem;
   display: flex;
   flex-direction: column;
   justify-content: center;
   margin-left: 5rem;
}

#page-header .top-bar-container .link {
   margin-bottom: 1rem;
   font: 700 2rem DM Sans;
   line-height: 2.6rem;
   text-align: center;
   color: #FFF;
   text-decoration: none;
}

#page-header .top-bar-container .link-selected {
   margin-bottom: 1rem;
   font: 700 2rem DM Sans;
   line-height: 2.6rem;
   text-align: center;
   color: #E72631;
   text-decoration: none;
}

#page-header .top-bar-container .line {
   width: 100%;
   border-bottom: 3px solid #262626;
}

#page-header .top-bar-container .line-selected {
   width: 100%;
   border-bottom: 3px solid #A51D17;
}

#page-header .top-bar-container .top-bar-right .notification {
   width: 2.2rem;
   height: 2.2rem;
   color: #FFF;
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0 2rem 1.3rem 4rem;
}

#page-header .top-bar-container a svg {
   width: 4rem;
   height: 4rem;
}

#page-header .top-bar-container .avatar {
   width: 4.5rem;
   height: 4.5rem;
   line-height: 0;
   border: 2px solid #FFFFFF;
   filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.4));
   border-radius: 2.25rem;
   vertical-align: middle;
   overflow: hidden;
   margin-bottom: 1rem;
   transition: linear 0.25s;
   cursor: pointer;
   color: #FFF;
   font: 700 1.6rem DM Sans;
   text-decoration: none;
   display: flex;
   justify-content: center;
   align-items: center;
}

#page-header .top-bar-container .avatar img {
   width: 4.5rem;
   height: 4.5rem;
   border-radius: 2.25rem;
   vertical-align: middle;
   overflow: hidden;
   object-fit: cover;
}

#page-header .top-bar-container .avatar:hover {
   transition: ease-out 0.2s;
   border: 2px solid rgba(255, 255, 255, 0.4);
   -webkit-transition: ease-out 0.2s;
}

@media(min-width: 1100px) {
   #page-header {
      height: 11rem;
      justify-content: center;
   }
   #page-header .top-bar-container {
      width: 120rem;
      padding: 0;
   }
   #page-header .top-bar-container .logo {
      margin-left: -3rem;
   }
}
