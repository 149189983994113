#modal-users-details {
   width: 48.4rem;
}

#modal-users-details .modal-body {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background: #F6F8F9;
   border-radius: 0.5rem;
   box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.4);
   padding: 3rem;
}

#modal-users-details .container {
   width: 100%;
   background: transparent;
   border: 0;
}

#modal-users-details .container h1 {
   font: 700 3rem Inter;
   line-height: 3.6rem;
   color: #000;
   margin-bottom: 3rem;
}

#modal-users-details .container .info-container {
   display: flex;
   flex-direction: column;
   margin-top: 2rem;
}

#modal-users-details .container .info-readonly-container {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
    margin-top: 2rem;
}

#modal-users-details .container .switch-container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
    margin-top: 2rem;
}

#modal-users-details .container label {
   font: 500 1.6rem Inter;
   line-height: 1.9rem;
   color: #696F79;
}

#modal-users-details .container input {
   height: 5.5rem;
   margin-top: 0.5rem;
   border-radius: 0.8rem;
   background: var(--color-input-background);
   border: 1px solid var(--color-input-border);
   outline: none;
   padding: 0 1.6rem;
   font: 500 1.4rem Inter;
   color: #000;
   cursor: pointer;
}

#modal-users-details .container span {
   padding: 0 1rem;
   font: 500 1.4rem Inter;
   color: #000;
}

#modal-users-details .container input:not([readonly]):focus {
   border: 2px solid var(--color-button-submit);
}

#modal-users-details .container input:read-only {
   background-color: #E9E9E9;
   cursor: default;
}

#modal-users-details footer {
   display: flex;
   flex-direction: column;
   margin-top: 4.5rem;
}

#modal-users-details .save-update-button {
   width: 100%;
   height: 5.5rem;
   background: var(--color-button-submit);
   border-radius: 0.8rem;
   display: flex;
   justify-content: center;
   align-items: center;
   font: 700 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #FFF;
   transition: background-color 0.2s;
   outline: none;
}

#modal-users-details .save-update-button:hover {
   background-color: var(--color-button-submit-dark);
}

#modal-users-details .block-button {
   width: 100%;
   height: 5.5rem;
   background: #97979A;
   border-radius: 0.8rem;
   display: flex;
   justify-content: center;
   align-items: center;
   font: 700 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #FFF;
   transition: background-color 0.2s;
   margin-top: 1.7rem;
   outline: none;
}

#modal-users-details .block-button:hover {
   background-color: #878788;
}

