#modal-tasks-details .modal-body {
   flex: 1;
   display: flex;
   justify-content: center;
   align-items: center;
   background: #F6F8F9;
   border-radius: 0.8rem;
   box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.4);
   padding-bottom: 3rem;
}

#modal-tasks-details .modal-body .container {
   background: transparent;
   border: 0;
}

#modal-tasks-details .container main .title-button {
   margin-bottom: 2.5rem;
}

#modal-tasks-details .container main .title {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 1.8rem 1.2rem 0.8rem;
   margin-bottom: 1rem;
}

#modal-tasks-details .container main .title input {
   width: 80%;
   font: 700 2rem DM Sans;
   line-height: 2.6rem;
   color: #161719;
   margin-right: 1.7rem;
   border: 0;
   background: #F6F8F9;
   padding: 1rem 1.6rem;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

#modal-tasks-details .container main .title svg {
   width: 1.6rem;
   height: 1.6rem;
   color: #231F20;
}

#modal-tasks-details .container main .title .save-task-button {
   width: 14rem;
   height: 3.6rem;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #1E8730;
   box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);
   border-radius: 0.6rem;
   font: 500 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #FFF;
   outline: 0;
}

#modal-tasks-details .container main .title .save-task-button:hover {
   background-color: #1b722a;
}

#modal-tasks-details .container main .title .save-task-button:disabled {
   background-color: #E0E0E0;
   cursor: not-allowed;
   color: #999;
}

#modal-tasks-details .container main .team-type-and-user-container {
   height: 4rem;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 1.5rem;
   padding: 0 2.8rem;
}

#modal-tasks-details .container main .type-container {
   height: 2.1rem;
   display: flex;
   flex-direction: row;
   align-items: center;
   margin-left: 2.8rem;
}

#modal-tasks-details .container main .responsible-container {
   height: 2.1rem;
   display: flex;
   flex-direction: row;
   align-items: center;
}

#modal-tasks-details .container main .user-created-container {
   height: 2.1rem;
   display: flex;
   flex-direction: row;
   align-items: center;
   padding: 0 2.5rem;
}

#modal-tasks-details .container main .type-container span {
   font: 400 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #676768;
   margin-right: 1rem;
}

#modal-tasks-details .container main .responsible-container span {
   font: 400 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #676768;
   margin-right: 1rem;
}

#modal-tasks-details .container main .user-created-container span {
   font: 400 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #676768;
   margin-right: 1rem;
}

#modal-tasks-details .container main .user-pill {
   background: #E0E0E0;
   height: 2.1rem;
   border-radius: 0.5rem;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 0 1rem 0 1rem;
   cursor: pointer;
}

#modal-tasks-details .container main .user-pill span {
   font: 400 1.2rem DM Sans;
   line-height: 1.6rem;
   text-transform: capitalize;
   color: #000;
}

#modal-tasks-details .container main .user-created-pill {
   background: #E0E0E0;
   height: 2.1rem;
   border-radius: 0.5rem;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 0 1rem 0 1rem;
}

#modal-tasks-details .container main .user-created-pill span {
   font: 400 1.2rem DM Sans;
   line-height: 1.6rem;
   text-transform: capitalize;
   color: #000;
}

#modal-tasks-details .container main .type-pill {
   background: #E0E0E0;
   height: 2.1rem;
   border-radius: 0.5rem;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 0 1rem 0 1rem;
   cursor: pointer;
}

#modal-tasks-details .container main .type-pill span {
   font: 400 1.2rem DM Sans;
   line-height: 1.6rem;
   text-transform: capitalize;
   color: #000;
}

#modal-tasks-details .container main .team-container-general {
   display: flex;
   align-items: center;
   justify-content: 'space-between';
   cursor: pointer;
   overflow: hidden;
}

#modal-tasks-details .container main .team-container-general .team-title {
   margin-right: 1rem;
   font: 400 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #676768;
}

#modal-tasks-details .container main .team-container-general .private-container {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   padding: 0.8rem 1rem;
   font: 400 1.2rem DM Sans;
   line-height: 1.6rem;
   width: 11rem;
   height: 2.1rem;
   overflow: hidden;
   color: #161719;
}

#modal-tasks-details .container main .team-container-general .pill {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   padding: 0.8rem 0.8rem 0.8rem 1.5rem;
   border-radius: 3rem;
   background: #56CCF2;
   color: #FCFCFF;
   font: 400 1.2rem DM Sans;
   line-height: 1.6rem;
   text-transform: capitalize;
   /* width: 11rem; */
   height: 2.1rem;
   overflow: hidden;
}

#modal-tasks-details .container main .estimated-date-container {
   height: 4rem;
   display: flex;
   align-items: center;
   margin-bottom: 1.5rem;
   padding: 0 2.8rem;
}

#modal-tasks-details .container main .estimated-date-container .date-container {
   display: flex;
   flex-direction: row;
   align-items: center;
   width: 100%;
   margin-right: 1rem;
   font: 400 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #676768;
}

#modal-tasks-details .container main .estimated-date-container .date-container span {
   padding-right: 1rem;
}

#modal-tasks-details .container main .estimated-date-container .date-container .date-pill {
   background: #E0E0E0;
   height: 2.1rem;
   min-width: 10rem;
   border-radius: 0.5rem;
   padding: 0 1rem 0 1rem;
}

#modal-tasks-details .container main .react-datepicker-wrapper, .react-datepicker__input-container {
   width: 100%;
   height: 100%;
}

#modal-tasks-details .container main .estimated-date-container .date-container .date-pill .button-date {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   height: 100%;
   width: 100%;
   font: 400 1.2rem DM Sans;
   line-height: 1.6rem;
   color: #000;
   outline: none;
}

#modal-tasks-details .container main .estimated-date-container .date-container .date-pill button span {
   margin-left: 1rem;
}

#modal-tasks-details .container main .react-datepicker {
   width: 100%;
   font: 500 1.2rem Inter;
   color: #333333;
}

#modal-tasks-details .container main .react-datepicker button {
   outline: none;
}

#modal-tasks-details .container main
.react-datepicker__month-container,
.react-datepicker__month {
   width: 17rem;
}

#modal-tasks-details .container main .react-datepicker__current-month {
   font: 700 1.2rem Inter;
   color: #333333;
}

#modal-tasks-details .container main .react-datepicker__day-names {
   font: 500 1rem Inter;
   color: #333333;
}

#modal-tasks-details .container main .input-block-details {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   margin: 3rem 2rem;
}

#modal-tasks-details .container main .input-block-details h3 {
   font: 700 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #676768;
   margin-bottom: 1.2rem;
}

#modal-tasks-details .container main .input-block-details textarea {
   width: 100%;
   background: #FCFCFF;
   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
   border-radius: 1rem;
   padding: 1.5rem;
   outline: none;
   border: 1px solid #D3E2E5;
   resize: vertical;
   font: 400 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #979794;
}

#modal-tasks-details .container main .input-block-details textarea::placeholder {
   font: 400 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #979794;
}

#modal-tasks-details .container main .input-block-details .images-container {
   display: grid;
   grid-template-columns: repeat(7, 1fr);
   grid-gap: 1.4rem;
   margin-bottom: 2rem;
}

#modal-tasks-details .container main .input-block-details .images-container .image-border {
   width: 8rem;
   height: 8rem;
   background: #FCFCFF;
   border-radius: 1rem;
   border: 0.1px dashed #D3E2E5;
   filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
   position: relative;
   overflow: hidden;
   display: flex;
   justify-content: center;
   align-items: center;
}

#modal-tasks-details .container main .input-block-details .images-container .image-border .file-info {
   width: 8rem;
   height: 8rem;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   overflow: hidden;
   cursor: pointer;
}

#modal-tasks-details .container main .input-block-details .images-container .image-border .file-info span {
   font: 400 0.9rem DM Sans;
   color: #979797;
   text-align: center;
   margin-top: 0.7rem;
}

#modal-tasks-details .container main .input-block-details .images-container .image-border .delete-button {
   width: 2.4rem;
   height: 2.4rem;
   position: absolute;
   top: 0;
   right: 0;
   background: #F1F1F1;
   border-radius: 0 1rem;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
}

#modal-tasks-details .container main .input-block-details .images-container .image-border .upload-button {
   width: 2.4rem;
   height: 2.4rem;
   position: absolute;
   bottom: 0;
   right: 0;
   /* left: 0; */
   background: #F1F1F1;
   border-radius: 1rem 0;
   /* border-radius: 0 1rem; */
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
}

#modal-tasks-details .container main .input-block-details .images-container .image-border img {
   width: 8rem;
   height: 8rem;
   object-fit: cover;
   border-radius: 1rem;
   cursor: pointer;
}

#modal-tasks-details .container main .input-block-details .new-image {
   width: 8rem;
   height: 8rem;
   background: rgba(255, 255, 255, 0.8);
   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
   border: 1px dashed #D3E2E5;
   border-radius: 1rem;
   cursor: pointer;
   display: flex;
   justify-content: center;
   align-items: center;
}

#modal-tasks-details .container main input[type=file] {
   display: none;
}

#modal-tasks-details .container main .input-block-details .checklist-master-container {
   padding: 0 1.2rem;
   width: 100%;
   margin-bottom: 2rem;
   position: relative;
}

#modal-tasks-details .container main .input-block-details .checklist-master-container .checklist-master {
   margin-bottom: 2rem;
}

#modal-tasks-details .container main .input-block-details .checklist-master-container .checklist-title-container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 0.8rem;
   padding: 0.5rem 1.5rem 0.5rem 0;
   height: 3.7rem;
}

#modal-tasks-details .container main .input-block-details .checklist-master-container .checklist-title-container .checklist-title {
   width: 90%;
}

#modal-tasks-details .container main .input-block-details .checklist-master-container .checklist-title-container .checklist-title .input-master-checklist-edit {
   width: 100%;
   font: 500 1.4rem DM Sans;
   line-height: 1.4rem;
   color: #676768;
   background: #ECECEC;
   height: 3.7rem;
   outline: none;
   margin: 0;
}

#modal-tasks-details .container main .input-block-details .checklist-master-container .checklist-title-container label {
   font: 500 1.4rem DM Sans;
   line-height: 1.4rem;
   color: #676768;
   padding-left: 1.6rem;
}

#modal-tasks-details .container main .input-block-details .checklist-master-container .checklist-title-container svg {
   cursor: pointer;
}

#modal-tasks-details .container main .input-block-details .checklist-master-container ul {
   width: 100%;
   margin: 0;
   padding: 0;
   background: var(--color-input-background);
   border-radius: 1rem;
   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

#modal-tasks-details .container main .input-block-details ul li {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   height: 3.7rem;
   position: relative;
   color: #979794;
   font: 400 1.3rem DM Sans;
   transition: 0.2s;
   padding: 0 1.3rem;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

#modal-tasks-details .container main .input-block-details ul li input[type=checkbox] {
   height: 1.6rem;
   width: 1.6rem;
   margin-right: 1.3rem;
   cursor: pointer;
}

#modal-tasks-details .container main .input-block-details ul li .check-container {
   content: '';
   position: relative;
   border: 2px solid #c7c4c4;
   border-radius: 0.3rem;
   height: 1.6rem;
   width: 1.6rem;
   margin-right: 1.3rem;
   cursor: pointer;
}

#modal-tasks-details .container main .input-block-details ul li:hover {
   background: #f0f0f0;
}

#modal-tasks-details .container main .input-block-details ul li .checked::before {
   content: '';
   position: absolute;
   border-color: #000;
   border-style: solid;
   border-width: 0 0.2rem 0.2rem 0;
   bottom: 0.2rem;
   left: 0.4rem;
   transform: rotate(45deg);
   height: 1.5rem;
   width: 0.7rem;
   /* z-index: 1; */
}

#modal-tasks-details .container main .input-block-details #checklist .input-checklist {
   color: #979794;
   font: 400 1.3rem DM Sans;
   background: #ECECEC;
   height: 3.7rem;
   outline: none;
   padding: 0 1.6rem;
   border-radius: 0 0 1rem 1rem;
   border: 0;
   margin: 0;
   z-index: 0;
}

#modal-tasks-details .container main .input-block-details #checklist .input-checklist::placeholder {
   color: #979794;
   font: 400 1.3rem DM Sans;
}

#modal-tasks-details .container main .input-block-details #checklist .checklist-content-container {
   width: 100%;
   cursor: pointer;
   word-break: break-all;
}

#modal-tasks-details .container main .input-block-details #checklist .checklist-content-container .input-checklist-edit {
   color: #acaca9;
   font: 400 1.3rem DM Sans;
   background: #ECECEC;
   height: 3.7rem;
   outline: none;
   padding: 0 1.6rem;
   border-radius: 1rem;
   /* border: 0; */
   margin: 0;
   z-index: 0;
}

#modal-tasks-details .container main .input-block-details .input-container-checklist {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   background: #fffefe;
   cursor: pointer;
   border-radius: 0 0 1rem 1rem;
}

#modal-tasks-details .container main .input-block-details .input-container-checklist .add-checklist {
   margin: 0 1rem;
}

#modal-tasks-details .container main .input-block-details #checklist input:-webkit-autofill {
   -webkit-box-shadow: 0 0 0px 1000px var(--color-input-background) inset !important;
   box-shadow: 0 0 0px 1000px var(--color-input-background) inset !important;
}

#modal-tasks-details .container main .input-block-details input {
   width: 100%;
   height: 7.7rem;
   margin-top: 0.8rem;
   border-radius: 0.8rem;
   background: var(--color-input-background);
   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
   border: 1px solid var(--color-input-border);
   outline: none;
   padding: 0 1.6rem;
   font: 400 1.7rem DM Sans;
}

#modal-tasks-details .container main .input-block-details input:-webkit-autofill {
   -webkit-box-shadow: 0 0 0px 1000px var(--color-input-background) inset !important;
   box-shadow: 0 0 0px 1000px var(--color-input-background) inset !important;
}

#modal-tasks-details .container main .input-block-details input:focus {
   border: 2px solid var(--color-button-submit);
}

#modal-tasks-details .container main .input-block-details input::placeholder {
   font: 400 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #91919F;
}

#modal-tasks-details .container main .input-block-details .comments-content {
   width: 100%;
   max-height: 33.6rem;
   background: #FCFCFF;
   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
   border-radius: 1rem;
   margin-bottom: 1.1rem;
   overflow-y: auto;
   overflow-x: hidden;
}

#modal-tasks-details .container main .input-block-details .comments-content .comments-content-inside {
   background: #FCFCFF;
   padding-bottom: 1.4rem;
   padding-top: 1rem;
   display: flex;
   justify-content: flex-end;
   flex-direction: column;
}

#modal-tasks-details .container main .input-block-details .comments-content .comment-ballon {
   width: 30.8rem;
   background: #F4F4F4;
   border-radius: 1rem;
   padding: 1.1rem 1.4rem;
   margin: 0.6rem 1.7rem;
   font: 400 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #979494;
   overflow: hidden;
}

#modal-tasks-details .container main .input-block-details .comments-content .comment-ballon .user {
   text-transform: capitalize;
}

#modal-tasks-details .container main .input-block-details .comments-content .comment-ballon .comment-date {
   display: flex;
   justify-content: flex-end;
   margin-top: 1.5rem;
   font: 400 1rem DM Sans;
   line-height: 1.3rem;
   color: #979494;
}

#modal-tasks-details .container main .input-block-details .input-container {
   width: 100%;
   position: relative;
}

#modal-tasks-details .container main .input-block-details .input-container textarea {
   background: #FCFCFF;
   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
   border-radius: 1rem;
   padding: 1.5rem;
   outline: none;
   border: 1px solid #D3E2E5;
   resize: vertical;
}

#modal-tasks-details .container main .input-block-details .input-container .send-message {
   position: absolute;
   right: 2rem;
   top: 2.4rem;
   cursor: pointer;
}

#modal-tasks-details .container main .input-block-details .input-container .update-description {
   position: absolute;
   right: 2rem;
   bottom: 2.4rem;
   cursor: pointer;
}

#modal-tasks-details .container .dropdown {
   position: relative;
   display: inline-block;
}

#modal-tasks-details .container .checklist-menu {
   position: absolute;
   display: block;
   height: 8.4rem;
   width: 21.9rem;
   filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.4));
   overflow: hidden;
   z-index: 1;
   top: 3rem;
}

#modal-tasks-details .container .checklist-menu button {
   width: 100%;
   height: 4.2rem;
   background-color: #E9EEF2;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   font: 400 1.2rem DM Sans;
   line-height: 1.6rem;
   color: #000;
   border-bottom: 2px solid #FFF;
   outline: none;
   padding: 0 1.3rem;
   transition: background-color 0.2s;
}

#modal-tasks-details .container .checklist-menu button:hover {
   background-color: #d7dce0;
}

#modal-tasks-details .container .menu-select-team {
   position: absolute;
   display: block;
   z-index: 1;
   top: 2.2rem;
   left: 5.8rem;
}

#modal-tasks-details .container .menu-select-type {
   position: absolute;
   display: block;
   z-index: 1;
   top: 2.2rem;
   left: 6.8rem;
}

#modal-tasks-details .container .menu-select-user {
   position: absolute;
   display: block;
   z-index: 1;
   top: 2.2rem;
   left: 12.4rem;
}

#modal-tasks-details .container .select-container {
   /* width: 20rem; */
   background-color: #FCFCFF;
   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
   border-radius: 1rem;
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   flex-direction: column;
}

#modal-tasks-details .container .select-container .items-team {
   width: 100%;
   height: 3.2rem;
   border-bottom: 1px solid #EBEBEB;
   outline: none;
   transition: opacity 0.2s;
   padding: 0 1rem;
   font: 400 1.2rem DM Sans;
   text-transform: capitalize;
   line-height: 1.6rem;
   color: #676768;
   cursor: pointer;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

#modal-tasks-details .container .select-container .items-team:hover {
   background-color: #56CCF2;
   color: #FFF;
   border-radius: 1rem;
}

#modal-tasks-details .container .select-container .item-team-selected {
   width: 100%;
   height: 3.2rem;
   border-bottom: 1px solid #EBEBEB;
   outline: none;
   transition: opacity 0.2s;
   padding: 0.5rem 1rem;
   font: 400 1.2rem DM Sans;
   line-height: 1.6rem;
   text-transform: capitalize;
   cursor: pointer;
   background-color: #56CCF2;
   border-radius: 1rem;
   color: #FFF;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

#modal-tasks-details .container .select-container .items-type {
   width: 100%;
   height: 3.2rem;
   border-bottom: 1px solid #EBEBEB;
   outline: none;
   transition: opacity 0.2s;
   padding: 0.5rem 1rem;
   font: 400 1.2rem DM Sans;
   text-transform: capitalize;
   line-height: 1.6rem;
   color: #676768;
   cursor: pointer;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

#modal-tasks-details .container .select-container .items-type:hover {
   background-color: #E0E0E0;
   border-radius: 0.5rem;
   color: #000;
}

#modal-tasks-details .container .select-container .item-type-selected {
   width: 100%;
   height: 3.2rem;
   border-bottom: 1px solid #EBEBEB;
   outline: none;
   transition: opacity 0.2s;
   padding: 0.5rem 1rem;
   font: 400 1.2rem DM Sans;
   line-height: 1.6rem;
   text-transform: capitalize;
   background-color: #E0E0E0;
   cursor: pointer;
   border-radius: 0.5rem;
   color: #000;
   display: flex;
   justify-content: flex-start;
   align-items: center;
}

#modal-tasks-details .container .select-container .items-user {
   width: 100%;
   height: 3.2rem;
   border-bottom: 1px solid #EBEBEB;
   outline: none;
   transition: opacity 0.2s;
   padding: 0.5rem 1rem;
   font: 400 1.2rem DM Sans;
   text-transform: capitalize;
   line-height: 1.6rem;
   color: #676768;
   cursor: pointer;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

#modal-tasks-details .container .select-container .items-user:hover {
   background-color: #E0E0E0;
   border-radius: 0.5rem;
   color: #000;
}

#modal-tasks-details .container .select-container .item-user-selected {
   width: 100%;
   height: 3.2rem;
   border-bottom: 1px solid #EBEBEB;
   outline: none;
   transition: opacity 0.2s;
   padding: 0.5rem 1rem;
   font: 400 1.2rem DM Sans;
   line-height: 1.6rem;
   text-transform: capitalize;
   background-color: #E0E0E0;
   cursor: pointer;
   border-radius: 0.5rem;
   color: #000;
   display: flex;
   justify-content: flex-start;
   align-items: center;
}

#modal-tasks-details .container footer {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   padding-left: 2rem;
}

#modal-tasks-details .container footer .complete-task-button {
   width: 14rem;
   height: 3.6rem;
   display: flex;
   justify-content: center;
   align-items: center;
   background: #6B2722;
   box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
   border-radius: 0.6rem;
   font: 500 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #FFF;
   transition: background-color 0.2s;
   outline: none;
}

#modal-tasks-details .container footer .complete-task-button:hover {
   background-color: #5e211d;
}
