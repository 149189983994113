#page-header-team-task {
   width: 100vw;
   height: 11rem;
   display: flex;
   flex-direction: row;
   background: var(--color-background-top);
   overflow: hidden;
}

#page-header-team-task .top-bar-container {
   width: 100vw;
   padding: 0 4rem;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

#page-header-team-task .top-bar-container .logo {
   width: 9.8rem;
   height: 9.8rem;
   margin-left: -5rem;
}

#page-header-team-task .top-bar-container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}

#page-header-team-task .top-bar-container .top-bar-right {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}

#page-header-team-task .top-bar-container .top-bar-right .link-container {
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

#page-header-team-task .top-bar-container .top-bar-right .link-selected {
   margin-bottom: 1rem;
   font: 700 2rem DM Sans;
   line-height: 2.6rem;
   text-transform: capitalize;
   text-align: center;
   color: #E72631;
   text-decoration: none;
}

#page-header-team-task .top-bar-container .top-bar-right .line-selected {
   width: 100%;
   border-bottom: 3px solid #A51D17;
}

#page-header-team-task .top-bar-container .top-bar-right .buttonBack {
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0 0 1.3rem 4rem;
   outline: none;
}

#page-header-team-task .top-bar-container a svg {
   width: 4rem;
   height: 4rem;
   color: var(--color-title-header);
}

#page-header-team-task .top-bar-container .top-bar-right .avatar {
   width: 4.5rem;
   height: 4.5rem;
   line-height: 0;
   border: 2px solid #FFFFFF;
   filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.4));
   border-radius: 2.25rem;
   vertical-align: middle;
   overflow: hidden;
   margin-bottom: 1rem;
   transition: linear 0.25s;
   cursor: pointer;
   color: #FFF;
   font: 700 1.6rem DM Sans;
   text-decoration: none;
   display: flex;
   justify-content: center;
   align-items: center;
}

#page-header-team-task .top-bar-container .top-bar-right .avatar img {
   width: 4.5rem;
   height: 4.5rem;
   border-radius: 2.25rem;
   vertical-align: middle;
   overflow: hidden;
   object-fit: cover;
}

#page-header-team-task .top-bar-container .top-bar-right .avatar:hover {
   transition: ease-out 0.2s;
   border: 2px solid rgba(255, 255, 255, 0.8);
   -webkit-transition: ease-out 0.2s;
}

@media(min-width: 1100px) {
   #page-header-team-task {
      height: 11rem;
      justify-content: center;
   }
   #page-header-team-task .top-bar-container {
      width: 120rem;
      padding: 0;
   }
   #page-header-team-task .top-bar-container .logo {
      margin-left: -3rem;
   }
}
