#page-future-notes {
   width: 100vw;
   height: 100vh;
   overflow: auto;
   overflow-x: hidden;
   background-color: #262626;
}

#page-future-notes .container {
   height: calc(100% - 18rem);
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-top: 5rem;
}

#page-future-notes .container main {
   flex: 1;
   width: 120rem;
   display: flex;
   flex-direction: row;
   align-items: flex-start;
   justify-content: center;
   overflow: hidden;
}

#page-future-notes .container main .column {
   background: #4A4747;
   width: 37.4rem;
   /* max-height: 100%; */
   height: 100%;
   box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.4);
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   border-radius: 0.5rem;
   margin-right: 3.9rem;
}

#page-future-notes .container main .column-two-parts {
   flex: 1;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   overflow-y: scroll;
}

#page-future-notes .container main .column-two-parts::-webkit-scrollbar {
   width: 0.7rem;
}

#page-future-notes .container main .column-two-parts::-webkit-scrollbar-thumb {
   background: #888;
   border-radius: 1rem;
}

#page-future-notes .container main .column-two-parts::-webkit-scrollbar-thumb:hover {
   background: #AAA;
}

#page-future-notes .container main .types {
   width: 37.4rem;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   padding: 1.5rem 2.5rem;
}

#page-future-notes .container main .types .task-type {
   font: 700 1.6rem DM Sans;
   line-height: 2.1rem;
   color: #F2F2F2;
}

#page-future-notes .container main .new-task {
   width: 37.4rem;
   height: 4.4rem;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   border-radius: 0.5rem;
   padding: 1.8rem 0 1.8rem 2.2rem;
   cursor: pointer;
   outline: none;
   background: #676768;
   transition: background-color 0.2s;
}

#page-future-notes .container main .new-task:hover {
   background: #999999;
}

#page-future-notes .container main .new-task span {
   margin-left: 1.2rem;
   font: 400 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #F2F2F2;
}

#page-future-notes .container .column-two-parts .no-tasks {
   margin-top: 4rem;
}

#page-future-notes .container .column-two-parts .no-tasks h1 {
   font: 700 2rem DM Sans;
   line-height: 2.1rem;
   color: #000;
}

#page-future-notes .container .column-two-parts .card-task {
   width: 34.2rem;
   height: 6.525rem;
   border-radius: 1rem;
   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 0.8rem;
   padding: 1.6rem 1.5rem 1.6rem 2.4rem;
}

#page-future-notes .container .column-two-parts .card-task .card-left {
   width: 15rem;
   height: 6.3rem;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-start;
   text-decoration: none;
   padding: 0.7rem 0;
}

#page-future-notes .container .column-two-parts .card-task .card-left .task-name-container {
   width: 24rem;
   height: 2.5rem;
   cursor: pointer;
}

#page-future-notes .container .column-two-parts .card-task .card-left .task-name-container h1 {
   font: 400 1.4rem DM Sans;
   line-height: 1.8rem;
   color: #4F4F4F;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

#page-future-notes .container .column-two-parts .card-task .card-left span {
   /* width: 10rem; */
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   font: 400 1.2rem DM Sans;
   line-height: 1.6rem;
   color: #828282;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

#page-future-notes .container .column-two-parts .card-task .card-left span svg {
   margin-right: 0.6rem;
}

#page-future-notes .container .column-two-parts .card-task .card-left .icons-left {
   width: 20rem;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   text-transform: capitalize;
   /* overflow: hidden; */
}

#page-future-notes .container .column-two-parts .card-task .color-button {
   display: none;
}

#page-future-notes .container .column-two-parts .card-task:hover .color-button {
   border: 0;
   cursor: pointer;
   display: block;
   margin-right: 2rem;
   margin-bottom: 0.5rem;
}

#page-future-notes .container .column-two-parts .card-task .color-button-space {
   border: 0;
   cursor: pointer;
   display: block;
   width: 1.7rem;
   height: 1.75rem;
   margin: 0;
   margin-right: 2rem;
}

#page-future-notes .container .column-two-parts .card-task:hover .color-button-space {
   display: none;
}

#page-future-notes .container .column-two-parts .card-task .color-button svg {
   transition: opacity 0.2s;
}

#page-future-notes .container .column-two-parts .card-task .color-button svg:hover {
   opacity: 0.5;
}

#page-future-notes .container .column-two-parts .card-task .card-right {
   height: 5.4rem;
   display: flex;
   flex-direction: row;
   align-items: flex-end;
   justify-content: space-between;
}

#page-future-notes .container .column-two-parts .card-task .button-container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}

#page-future-notes .container .column-two-parts .card-task .button-container span {
   width: 8.5rem;
   font: 400 1rem DM Sans;
   line-height: 1.3rem;
   color: #828282;
   margin-right: 0.7rem;
   text-align: right;
}

#page-future-notes .container .column-two-parts .card-task .send-button {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   border: 0;
   transition: opacity 0.2s;
}

#page-future-notes .container .column-two-parts .card-task .send-button img {
   width: 5.2rem;
   height: 5.2rem;
   border-radius: 1rem;
}

#page-future-notes .container .column-two-parts .card-task .send-button:hover {
   opacity: 0.7;
}

#page-future-notes .container .column-two-parts .card-task .button-gogogo-container span {
   font: 400 1rem DM Sans;
   line-height: 1.3rem;
   color: #BDBDBD;
   margin-bottom: 0.6rem;
}

#page-future-notes .container .column-two-parts .card-task button:focus {
   outline: 0;
}

.dropdown-color {
   position: relative;
   display: inline-block;
}

.dropdown-color .menu-select {
   position: absolute;
   display: block;
   z-index: 99;
   top: 2rem;
   right: 1.5rem;
}

.dropdown-color .select-container-color {
   width: 20rem;
   height: 10rem;
   background-color: #FCFCFF;
   border-radius: 0.6rem;
   border: 1px solid rgba(0, 0, 0, 0.1);
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   align-items: center;
   flex-wrap: wrap;
   padding: 1rem 1rem;
}

.dropdown-color .select-container-color .color {
   width: 3rem;
   height: 3rem;
   border-radius: 0.6rem;
   border: 1px solid rgba(0, 0, 0, 0.2);
}
